import React, {
    useEffect,
    useState,
} from "react"

import { Helmet } from "react-helmet"

import Div100vh from 'react-div-100vh'

import VideoPlayer from '../components/VideoPlayer'
import Slider from '../components/Slider'
import ModalHockeyPuck from '../components/ModalHockeyPuck'
import ModalContacts from '../components/ModalContacts'

import video1_MP4 from '../assets/videos/video1_MP4.mp4'
import video1_Webm from '../assets/videos/video1_Webm.webm'
import video2_MP4 from '../assets/videos/video2_MP4.mp4'

import HockeyPuck from '../assets/svg/hockeyPuck.svg'

import IronHead1 from '../assets/svg/ironHead1.svg'


import '../styles/reset.css'

import styles from './styles/styles.module.sass'



const IndexPage = () => {

    const videos = [
        {
            mp4: video2_MP4,
            webm: video2_MP4,
        },
    ]

    // "Утилитная" функция для определения индекса следующего видео
    const getNextVideoIndex = () => (
        currentVideo.index === videos.length - 1
            ? 0
            : currentVideo.index + 1
    )

    // Представление и логика слайдов
    const slides = [
        {
            clickHandler() {
                setCurrentVideo({
                    // Если выбрано последнее видео из коллекции вернутся к первому,
                    // иначе дальше
                    index: getNextVideoIndex(),

                    // После выбора видо оно сразу включается
                    processing: true,

                })
            },
            render: () => (
                <IronHead1
                    className={styles.ironHead}
                />
            )
        },
        {
            clickHandler() {
                setCurrentModalName('CONTACTS')
            },
            render: () => (
                <span className={styles.contactsText}>
                    {'contacts'}
                </span>
            )
        },
        {
            clickHandler() {
                setCurrentModalName('HOCKEY_PUCK')
            },
            render: () => (
                <HockeyPuck
                    className={styles.hockeyPuck}
                />
            )
        },
    ]


    // Выбранное видео, индекс и состояние воспроизведения
    const [
        currentVideo,
        setCurrentVideo
    ] = useState({
        index: 0,
        processing: true
    })


    // Чтобы не делать по стейты на модалку, создается 1 enum-стейт
    // "NONE" - ни одна модалка не открыта
    // "CONTACTS" - модалка с контактами
    // "HOCKEY_PUCK" - постер игры "шайбу-шайбу"
    const [
        currentModalName,
        setCurrentModalName,
    ] = useState('NONE')


    // Соотношение сторон экрана
    // "PORTRAIT"
    // "LANDSCAPE"
    const [
        screenAspectRatio,
        setScreenAspectRatio,
    ] = useState("LANDSCAPE")


    useEffect(() => {

        if (window.innerWidth < window.innerHeight) {
            setScreenAspectRatio(
                window.innerWidth < window.innerHeight
                    ? 'PORTRAIT'
                    : "LANDSCAPE"
            )
        }
    })


    return (

        <>

            <Helmet
                htmlAttributes={{
                    lang: 'ru',
                    charSet: 'utf-8',
                }}
                title={'IRONHEADS'}
                meta={[
                    // TODO Попросить Саню подогнать SEO
                    {
                        name: `description`,
                        content: 'IRONHEADS',
                    },
                    {
                        name: `viewport`,
                        content: 'width=device-width, initial-scale=1, maximum-scale=5',
                    },
                ]}

            />


            <Div100vh className={styles.main}>

                {currentVideo.processing
                    ? (
                        <VideoPlayer
                            className={`
                                ${styles.video}
                                ${screenAspectRatio === 'PORTRAIT' ? styles.__portraitAspectRatio : ''}
                            `}
                            video={videos[currentVideo.index]}
                            videoToLoad={videos[getNextVideoIndex()]}
                            closeVideo={() => {
                                // Оставить выбранным текущее видео, но отключить воспроизведение
                                setCurrentVideo({
                                    index: currentVideo.index,
                                    processing: false,
                                })
                            }}
                        />
                    )
                    : (
                        <Slider slides={slides}/>
                    )
                }

                {currentModalName === 'CONTACTS' &&(
                    <ModalContacts closeHandler={() => {
                        setCurrentModalName('NONE')
                    }} />
                )}


                {currentModalName === 'HOCKEY_PUCK' &&(
                    <ModalHockeyPuck
                        screenAspectRatio={screenAspectRatio}
                        closeHandler={() => {
                            setCurrentModalName('NONE')
                        }}
                    />
                )}


            </Div100vh>

        </>

    )


}

export default IndexPage
