import React, {
    useState
} from 'react'

import LeftArrow from "../../assets/svg/leftArrow.svg"
import RightArrow from "../../assets/svg/rightArrow.svg"

import styles from "./styles.module.sass"


const multiplySlides = (slides) => {

    const result = []

    for (let i = 0; i < 20; i++) {

        result.push(slides)

    }

    return result.flat()

}


const Slider = (props) => {


    // Саня попросил чтобы после последнего слайда не было "перемотки" влево.
    // Самому писать логику подстановки слайдов я не хочу, поэтом просто "размножу" слайды
    const multipliedSlides = multiplySlides(props.slides)


    // Текущий активный слайд
    const [
        currentSlideIndex,
        setCurrentSlideIndex,
    ] = useState(0)


    return (
        <div className={styles.sliderContainer}>


            <LeftArrow
                className={styles.sliderButton}
                onClick={() => setCurrentSlideIndex(
                    // Если слайд первый...
                    currentSlideIndex === 0
                        // На послений слайд
                        ? multipliedSlides.length - 1
                        // Иначе дальше назад
                        : currentSlideIndex - 1
                )}
            />

            <div className={styles.sliderOuterFrame}>


                <div className={styles.sliderFrame}>

                    <div
                        className={styles.sliderRow}
                        style={{
                            // Ширина определяется количеством слайдов
                            width: `${100 * multipliedSlides.length}%`,
                            // Текущий слайд определяет отрицательный марджин строки
                            marginLeft: `${-100 * currentSlideIndex}%`
                        }}
                    >

                        {multipliedSlides.map((slide, index) => (

                            <div
                                className={styles.slide}
                                key={index}
                                onClick={slide.clickHandler}
                            >

                                {
                                    index < props.slides.length
                                        ? (
                                            slide.render()
                                        )
                                        : (
                                            // Все слайды которые нагенерированы сверх того что передано в пропсах
                                            // оборачиваем в тэг `noindex`, чтобы их не индексировали поисковые боты.
                                            <noindex className={styles.noIndexBlock}>
                                                {slide.render()}
                                            </noindex>
                                        )
                                }


                            </div>

                        ))}

                    </div>


                </div>

            </div>


            <RightArrow
                className={styles.sliderButton}
                onClick={() => setCurrentSlideIndex(
                    // Если слайд последний...
                    currentSlideIndex === multipliedSlides.length - 1
                        // На первый слайд
                        ? 0
                        // Иначе дальше вперед
                        : currentSlideIndex + 1
                )}
            />

        </div>
    )

}


export default Slider