import React from 'react'
import { StaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

import CloseIcon from '../../assets/svg/closeIcon.svg'

import styles from './styles.module.sass'


const ModalHockeyPuck = (props) => (
    <StaticQuery
        query={graphql`
        query {
          hockeyPuckPoster: file(relativePath: { eq: "hockeyPuckPoster.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1900, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          },
        }
      `}
        render={data => (

            <div className={styles.container} >

                <CloseIcon
                    className={styles.closeIcon}
                    onClick={props.closeHandler}
                />

                <Img
                    className={`
                        ${styles.poster}
                        ${props.screenAspectRatio === 'PORTRAIT' ? styles.__portraitAspectRatio : ''}
                    `}
                    fluid={data.hockeyPuckPoster.childImageSharp.fluid}
                />

            </div>


        )}
    />
)


export default ModalHockeyPuck