import React, {
    useCallback,
    useEffect,
} from 'react'

import styles from './styles.module.sass'


const VideoPlayer = (props) => {

    const keydownHandler = useCallback((event) => {

        const codesToClose = [
            'Enter',
            'Escape',
            'Space',
        ]

        if (codesToClose.includes(event.code)) {
            props.closeVideo()
        }

    }, [])

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler, false)

        return () => {
            document.removeEventListener("keydown", keydownHandler, false)
        }
    }, [])

    return (
        <>
            <video
                onClick={props.closeVideo}
                onEnded={props.closeVideo}
                className={props.className || ''}
                autoPlay
                muted
            >
                <source src={props.video.webm} type="video/webm" />
                <source src={props.video.mp4} type="video/mp4" />
            </video>

            {/* Невидимый видеоплейер для подгрузки следующего видео */}
            <video className={styles.hiddenVideo}>
                <source src={props.videoToLoad.webm} type="video/webm" />
                <source src={props.videoToLoad.mp4} type="video/mp4" />
            </video>
        </>
    )

}


export default VideoPlayer