import React, {
    useState,
} from 'react'

import CloseIcon from '../../assets/svg/closeIcon.svg'
import FacebookIcon from '../../assets/svg/facebookIcon.svg'
import LinkedInIcon from '../../assets/svg/linkedInIcon.svg'
import WhatsAppIcon from '../../assets/svg/whatsAppIcon.svg'
import TelegramIcon from '../../assets/svg/telegramIcon.svg'


import Button from '../../components/Button'


import styles from './styles.module.sass'


const emailAddress = 'hello@ironheads.studio'

const ModalContacts = (props) => {

    const socialNetworks = [
        {
            href: '',
            icon: <FacebookIcon/>,
        },
        // {
        //     href: '',
        //     icon: <LinkedInIcon/>,
        // },
        // {
        //     href: '',
        //     icon: <WhatsAppIcon/>,
        // },
        {
            href: '',
            icon: <TelegramIcon/>,
        },
    ]


    return (

        <div className={styles.container}>

            <CloseIcon
                className={styles.closeIcon}
                onClick={props.closeHandler}
            />

            <div className={styles.form}>

                <h1 className={styles.header}>
                    {'contacts'}
                </h1>

                <a
                    href={`mailto:${emailAddress}`}
                    className={styles.email}
                >
                    {emailAddress}
                </a>


                {/*<div className={styles.socialNetworks}>*/}

                {/*    {socialNetworks.map(item => (*/}
                {/*        <a*/}
                {/*            className={styles.socialNetworks__item}*/}
                {/*            target={'_blank'}*/}
                {/*            href={item.href}*/}
                {/*        >*/}
                {/*            {item.icon}*/}
                {/*        </a>*/}
                {/*    ))}*/}

                {/*</div>*/}

                <p className={styles.copyright}>
                    {'© 2020 IRONHEADS, LLC. All Rights Reserved'}
                </p>


            </div>

        </div>

    )

}

export default ModalContacts
